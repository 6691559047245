import { useEffect, useState } from 'react';

import { getWindow } from '@change-corgi/core/window';

import { usePaypal } from 'src/app/shared/hooks/payments';
import { isLoaded } from 'src/app/shared/utils/async';
import type { PaymentMethodSaveOptions, VenmoClient } from 'src/app/shared/utils/payments';

export type Props = {
	paymentMethodSaveOptions: PaymentMethodSaveOptions;
};

export function useInitializeVenmoInstance({ paymentMethodSaveOptions }: Props): VenmoClient | null {
	const paypalState = usePaypal();

	const [venmo, setVenmo] = useState<VenmoClient | null>(null);

	useEffect(() => {
		(async function init() {
			if (isLoaded(paypalState)) {
				const venmoInstance = await getWindow().braintree.venmo.create({
					client: paypalState.braintreeClientInstance,
					paymentMethodUsage: paymentMethodSaveOptions.shouldSavePaymentMethod ? 'multi_use' : 'single_use',
				});
				setVenmo(venmoInstance);
			}
		})();
	}, [paypalState, paymentMethodSaveOptions]);

	return venmo;
}
