import { useEffect, useState } from 'react';

import { getWindow } from '@change-corgi/core/window';

import { usePaypal } from 'src/app/shared/hooks/payments';
import { isLoaded } from 'src/app/shared/utils/async';

export function useCanUseVenmo(): boolean {
	const paypal = usePaypal();
	const [canUseVenmo, setCanUseVenmo] = useState(false);

	useEffect(() => {
		(async function init() {
			if (isLoaded(paypal)) {
				const venmoInstance = await getWindow().braintree.venmo.create({
					client: paypal.braintreeClientInstance,
				});
				setCanUseVenmo(venmoInstance.isBrowserSupported());
			}
		})();
	}, [paypal]);
	return canUseVenmo;
}
