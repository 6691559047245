import type { OnApproveData } from '@paypal/paypal-js/types/components/buttons';

import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import { saveBraintreePaymentMethod } from 'src/app/shared/api/payments';
import type { WithTokenParams } from 'src/app/shared/types';
import type { PaymentMethodSaveOptions, PaymentType, PaypalCheckout } from 'src/app/shared/utils/payments';

type OnApproveParams = {
	email: string;
	onApproveData: OnApproveData;
	onTokenError: (paymentType: PaymentType, err: Error) => void;
	paypalCheckout: PaypalCheckout | null;
	paymentMethodSaveOptions: PaymentMethodSaveOptions;
	withToken: (params: WithTokenParams) => void | Promise<void>;
	prePaymentChecks?: (paymentType: PaymentType) => Promise<boolean>;
};

export async function onApprove(
	{
		email,
		onApproveData,
		onTokenError,
		paypalCheckout,
		paymentMethodSaveOptions,
		withToken,
		prePaymentChecks,
	}: OnApproveParams,
	utilityContext: UtilityContext,
): Promise<void> {
	if (!paypalCheckout) {
		return Promise.reject(new Error('No paypal checkout instance'));
	}

	const { nonce } = await paypalCheckout.tokenizePayment(
		Object.assign(onApproveData, {
			billingToken: String(onApproveData.billingToken),
			payerId: String(onApproveData.payerID),
		}),
	);

	if (prePaymentChecks && !(await prePaymentChecks('paypal'))) {
		return Promise.resolve();
	}

	if (paymentMethodSaveOptions.shouldSavePaymentMethod) {
		try {
			await saveBraintreePaymentMethod({
				token: nonce,
				email,
				usage: paymentMethodSaveOptions.usage,
				utilityContext,
				paymentType: 'PAYPAL',
			});
		} catch (error) {
			return onTokenError('paypal', error as Error);
		}
	}
	void withToken({ paymentType: 'paypal', token: nonce });
	return Promise.resolve();
}
