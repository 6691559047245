import type { MutableRefObject } from 'react';
import { useCallback, useRef } from 'react';

import type { WithTokenParams } from 'src/app/shared/types';
import type { PaymentMethodSaveOptions, PaymentType } from 'src/app/shared/utils/payments';

import { useInitializeVenmoInstance } from './hooks/useInitializeVenmoInstance';
import { useSavePaymentMethod } from './hooks/useSavePaymentMetod';

type Result = ModelHookResult<
	{
		ready: boolean;
		ref: MutableRefObject<HTMLButtonElement | null>;
	},
	{
		onClick: () => Promise<void>;
	}
>;

export type HookProps = {
	email: string;
	paymentMethodSaveOptions: PaymentMethodSaveOptions;
	beforeToken: (paymentType: PaymentType) => Promise<boolean>;
	onTokenError: (paymentType: PaymentType, err: Error) => void;
	onTokenInvalid: (paymentType: PaymentType, err: Error) => void;
	validate?: (paymentType: PaymentType) => Promise<boolean>;
	withToken: (params: WithTokenParams) => Promise<void>;
	prePaymentChecks?: (paymentType: PaymentType) => Promise<boolean>;
};

export function useVenmoButton({
	email,
	paymentMethodSaveOptions,
	beforeToken,
	onTokenError,
	withToken,
	validate,
	prePaymentChecks,
}: HookProps): Result {
	const ref = useRef<HTMLButtonElement | null>(null);
	const venmo = useInitializeVenmoInstance({ paymentMethodSaveOptions });
	const savePaymentMethod = useSavePaymentMethod();

	const onClick = useCallback(async () => {
		if (!venmo) return Promise.resolve();
		await beforeToken('venmo');
		if (validate) {
			await validate('venmo');
		}
		try {
			const { nonce } = await venmo.tokenize();
			if (prePaymentChecks && !(await prePaymentChecks('venmo'))) {
				return Promise.resolve();
			}
			await savePaymentMethod({ email, token: nonce, paymentMethodSaveOptions, onTokenError });
			void withToken({ paymentType: 'venmo', token: nonce });
		} catch (err) {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			void onTokenError('venmo', err as Error);
		}
		return Promise.resolve();
	}, [
		beforeToken,
		email,
		onTokenError,
		paymentMethodSaveOptions,
		prePaymentChecks,
		savePaymentMethod,
		validate,
		venmo,
		withToken,
	]);

	return {
		data: {
			ref,
			ready: !!venmo,
		},
		actions: {
			onClick,
		},
	};
}
