import React from 'react';

import type { HookProps } from './hooks/useVenmoButton';
import { useVenmoButton } from './hooks/useVenmoButton';

export type Props = HookProps & {
	loading: boolean;
	shouldEnableButton?: boolean;
};

export function VenmoButton(props: Props): React.JSX.Element | null {
	const {
		data: { ref, ready },
		actions: { onClick },
	} = useVenmoButton(props);

	if (!ready) return null;

	const { loading, shouldEnableButton } = props;

	return (
		<div sx={{ textAlign: 'center' }}>
			<button
				ref={ref}
				onClick={onClick}
				type="button"
				disabled={!shouldEnableButton}
				sx={{
					backgroundColor: !shouldEnableButton || loading ? '#008CFF91' : '#008CFF',
					width: '300px',
					borderRadius: '4px',
					cursor: 'pointer',
					padding: '5px',
				}}
			>
				<img
					src="https://static.change.org/payment-options/btn/venmo-white.svg"
					alt="venmo button logo"
					width="66"
					sx={{ paddingTop: '5px' }}
				/>
			</button>
		</div>
	);
}
