import { useUtilityContext } from '@change-corgi/core/react/utilityContext';
import type { PaymentMethodUsage } from '@change-corgi/middle-end-gql-schema';

import type { PaymentType } from 'src/app/shared/utils/payments';
import { saveStripePaymentMethod } from 'src/app/shared/utils/payments';

import { useSetupAndConfirm } from './useSetupAndConfirm';

export function useStripePermanentPaymentMethod(): (params: {
	paymentType: PaymentType;
	token?: string;
	accountId?: string;
	tokenType?: 'temporary' | 'permanent';
	usage: PaymentMethodUsage;
	email: string;
	countryCode: string;
	currencyCode: string;
}) => Promise<void> {
	const utilityContext = useUtilityContext();
	const setupAndConfirmPaymentMethod = useSetupAndConfirm();

	return async (params): Promise<void> => {
		const { token, usage, email, paymentType, tokenType, countryCode, currencyCode, accountId } = params;
		const paymentMethod = { id: token };
		await setupAndConfirmPaymentMethod({ paymentMethod, usage, paymentType, countryCode, currencyCode, accountId });
		if (tokenType === 'temporary' && paymentMethod.id) {
			await saveStripePaymentMethod(paymentMethod.id, email, usage, utilityContext, paymentType, accountId);
		}
	};
}
